import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import * as React from "react";
import {useTranslation} from 'react-i18next';
import DateTimeLabel, {DateTimeLabelFormat} from "../../../dateTimeLabel";
import {Profile} from "../../../modules/profile/ProfileTypes";
import styles from "./MealMoment.module.css";
import FoodTab from "./FoodTab";
import SelectProfileDialog from "./SelectProfileDialog";
import DisclaimerDialog from "./DisclaimerDialog"
import {useDispatch, useSelector} from "react-redux";
import {ApplicationState} from "../../../store";
import {selectMealMoment} from "../../../modules/order/OrderActions";
import {confirmNoMeals, getMealDays, selectMealDay} from "../../../modules/menu/MenuActions";
import {MealMoment} from "../../../modules/order/OrderTypes";
import { useNavigate } from "react-router-dom";
import { Logoff } from "../../../modules/auth/authActions";
import { approveDisclaimer } from "../../../modules/profile/ProfileActions";
import ConfirmDialog from "../../dialog/ConfirmationDialog";
import Box from "@mui/material/Box";

const MealMomentComponent: React.FC = () => {
    const dispatch = useDispatch()
    const myMenuState = useSelector((state: ApplicationState) => state.menu)
    const myOrderState = useSelector((state: ApplicationState) => state.order)
    const myProfileState = useSelector((state: ApplicationState) => state.profile)
    const activeProfile: Profile = myProfileState.profiles.find(pr => pr.id === myProfileState.activeProfileID);
    const authState = useSelector((state: ApplicationState) => state.auth)

    const mealDays = myMenuState ? myMenuState.mealDays : null
    const mealDayIndex = myOrderState ? myMenuState.mealDayIndex : 0
    const profile = activeProfile
    const patient = myProfileState.patient
    const guests = myProfileState.guests
    const hasMultipleProfiles = myProfileState.profiles.length > 1

    const [openDisclaimer, setOpenDisclaimer] = React.useState<boolean>(!patient.disclaimerAccepted)
    const [openNoMeals, setOpenNoMeals] = React.useState<boolean>(false)
    const [openSelectProfile, setOpenSelectProfile] = React.useState<boolean>(false)
    const [allMealsBlocked, setAllMealsBlocked] = React.useState<boolean>(false)
    const [isLoading, setIsLoading] = React.useState<boolean>(false)
    const {t, i18n} = useTranslation();
    const navigate = useNavigate()

    let chosenProfile: Profile = activeProfile;

    const handleCloseDisclaimer =  (accepted: boolean) => {        
        if (accepted) {           
            myProfileState.profiles.forEach(pf => pf.disclaimerAccepted = true);
            handleSelectProfile(chosenProfile);
            approveDisclaimer()(dispatch);
            setOpenDisclaimer(false);
        }
        else {
            setOpenDisclaimer(false);
            Logoff()(dispatch, authState);
        }        
    }

    const handleSelectProfile = async (profile: Profile) => {
        i18n.changeLanguage(profile.preferredLanguageCulture);
        setIsLoading(true)
        if (!profile.disclaimerAccepted) {
            chosenProfile = profile;
            setOpenDisclaimer(!profile.disclaimerAccepted)
        }
        else {
            await getMealDays(profile, dispatch).then(() => {
                setIsLoading(false)

            })
        }        
    }

    const handleChange = (event: any, tabIndex: any) => {
        dispatch(selectMealDay(tabIndex))
    };

    const handleClickOpenNoMeals = () => {
        setOpenNoMeals(true)
    };

    const handleClose = () => {
        setOpenNoMeals(false)
        setOpenSelectProfile(false)
    };


    const handleConfirmNoMeals = () => {
        confirmNoMeals(mealDays[mealDayIndex], activeProfile)(dispatch)
        handleClose();
    }

    const handleSelectMealMoment = async (mealMoment: MealMoment) => {
        setIsLoading(true)
        await selectMealMoment(mealMoment, dispatch).then((res) => {
                if (res) navigate(res)
                setIsLoading(false)
            }
        )
    }

    function TabLabel(dateString: string) {
        const dateValue = new Date(dateString);
        return <Box className={styles.tabLabel}>
            <Typography variant="subtitle2">
                <strong>{
                    <DateTimeLabel value={dateValue} type={DateTimeLabelFormat.Day}/>
                }</strong>
            </Typography>
            <DateTimeLabel value={dateValue} type={DateTimeLabelFormat.Date}/>
        </Box>;
    }


    if (!mealDays || isLoading) {
        return <CircularProgress className={styles.loadingSpinner}/>;
    }

    return <>
        <Paper elevation={1} square={true} className={styles.paper}>
            <Tabs
                value={mealDayIndex}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant={mealDays.length > 3 ? "scrollable" : "fullWidth"}
                scrollButtons={mealDays.length > 3}
                allowScrollButtonsMobile
                centered={mealDays.length <= 3}
            >
                {
                    mealDays.map((mealDay, i) =>
                        <Tab label={TabLabel(mealDay.mealMoments[0].date)} key={`${mealDay.mealDate}-${i}`}/>
                    )
                }
            </Tabs>
        </Paper>

        <Paper elevation={0} square={true} className={styles.paper}>
            {
                mealDays.map((value, index) => {
                    return <FoodTab key={index} mealDayIndex={mealDayIndex} index={index} day={mealDays[index]}
                                    hasMultipleProfiles={hasMultipleProfiles} profile={profile}
                                    selectMealMoment={handleSelectMealMoment} setAllMealsBlocked={setAllMealsBlocked}
                                    setOpenSelectProfile={setOpenSelectProfile}/>
                })
            }

            <Button id={styles.noMealButton} className={styles.noMealButton} onClick={handleClickOpenNoMeals} variant="contained" size="large"
                    sx={theme => allMealsBlocked ? {} : {
                        backgroundColor: theme.cancelComponents.main,
                        color: theme.cancelComponents.contrastText
                    }}
                    disabled={allMealsBlocked}>
                {t('meal.MgeenMlt')}
            </Button>
        </Paper>

        <DisclaimerDialog openDisclaimerDialog={openDisclaimer} handleClose={handleCloseDisclaimer} />

        <SelectProfileDialog guests={guests} handleClose={handleClose} openSelectProfile={openSelectProfile}
                             patient={patient} selectProfile={handleSelectProfile}/>
        <ConfirmDialog title={t('order.DmenuGeen')} closeText={t("algemeen.Annuleer")} confirmText={t("algemeen.Bevestigen")} handleConfirm={handleConfirmNoMeals} message={t("order.Dzeker")} open={openNoMeals} handleClose={handleClose}/>
    </>;
}

export default (MealMomentComponent);
